<template>
  <div>
    <gl-title title="详情" backIcon></gl-title>
    <gl-card v-loading="loading">
      <el-form :model="formData" ref="form" label-width="130px" size="mini" v-loading="loading">
        <el-form-item prop="consignee" label="昵称">
          <div> {{formData.consignee}} </div>
        </el-form-item>
        <el-form-item prop="phone" label="联系电话">
          <div> {{formData.phone}} </div>
        </el-form-item>
        <el-form-item prop="phone" label="支付方式">
          <div> {{formData.phone}} </div>
        </el-form-item>
        <el-form-item prop="title" label="商品标题">
          <div> {{formData.title}} </div>
        </el-form-item>
        <el-form-item prop="title" label="商品规格">
          <div> {{formData.specification}} </div>
        </el-form-item>
        <el-form-item prop="id" label="状态">
          <div> {{formData.id}} </div>
        </el-form-item>
        <el-form-item prop="id" label="单价">
          <div> {{formData.id}} </div>
        </el-form-item>
        <el-form-item prop="id" label="数量">
          <div> {{formData.id}} </div>
        </el-form-item>
        <el-form-item prop="id" label="优惠">
          <div> {{formData.id}} </div>
        </el-form-item>
        <el-form-item prop="id" label="商品总价(元)">
          <div> {{formData.id}} </div>
        </el-form-item>
        <el-form-item prop="id" label="运费">
          <div> {{formData.id}} </div>
        </el-form-item>
        <el-form-item prop="id" label="订单实收款">
          <div> text </div>
        </el-form-item>
        <el-form-item prop="id" label="结算金额">
          <div> 税费|成本 </div>
        </el-form-item>
        <el-form-item prop="id" label="订单编号">
          <div> text </div>
        </el-form-item>
        <el-form-item prop="id" label="订单时间">
          <div> text </div>
        </el-form-item>
        <el-form-item prop="id" label="付款时间">
          <div> text </div>
        </el-form-item>
        <el-form-item prop="id" label="确认时间">
          <div> text </div>
        </el-form-item>
      </el-form> 
      <!-- <gl-flex>
        <el-button @click="handleClose" >关闭</el-button>
      </gl-flex> -->
    </gl-card>
  </div>
</template>

<script>
export default {
  data(){
    return{
      loading: false,
      formData: {},
    }
  },
  methods: {
    handleClose(){
      this.$router.go(-1)
    },
  }
}
</script>

<style>

</style>